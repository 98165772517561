var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"490px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{class:_vm.$vuetify.theme.dark ? 'primary lighten-1' : 'primary lighten-3',attrs:{"disable-hover":true}},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-center px-6 pb-0 pt-6"},[_c('div',{staticClass:"text-h6 font-weight-medium"},[_vm._v("Confirm offer")]),_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.vm.buying},on:{"click":function($event){return _vm.cancel(false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"px-6 pt-0 pb-6"},[_c('div',{staticClass:"mt-6",class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[_c('div',{staticClass:"font-weight-600 text-body-2 mb-3"},[_vm._v("Your offer for NFT #"+_vm._s(_vm.vm.keyId))]),_c('div',{staticClass:"common-border pa-4 border-radius-16 fill-width text-body-2"},[_c('div',{staticClass:"d-flex justify-space-between mb-1",class:{
              'text-caption': _vm.$vuetify.breakpoint.xs,
            }},[_c('div',{staticClass:"font-weight-normal"},[_vm._v("Total sell price :")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("round")(_vm.vm.offerPriceInput,2),2, 0))+" "+_vm._s(_vm.vm.tradeToken)+" ")])]),_c('div',{staticClass:"d-flex justify-space-between mb-1",class:{
              'text-caption': _vm.$vuetify.breakpoint.xs,
            }},[_c('div',{staticClass:"font-weight-normal"},[_vm._v("Sell price ratio :")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" 1 "+_vm._s(_vm.vm.tokenName)+" = "+_vm._s(_vm._f("formatNumber")(_vm._f("round")(_vm.vm.offerRatioInput,6),6, 0))+" "+_vm._s(_vm.vm.tradeToken)+" ")])]),_c('div',{staticClass:"d-flex justify-space-between mb-1",class:{
              'text-caption': _vm.$vuetify.breakpoint.xs,
            }},[_c('div',{staticClass:"font-weight-normal"},[_vm._v("Token amount :")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.vm.remainingToken,6, 0))+" "+_vm._s(_vm.vm.tokenName))])]),_c('div',{staticClass:"d-flex justify-space-between",class:{
              'text-caption': _vm.$vuetify.breakpoint.xs,
            }},[_c('div',{staticClass:"font-weight-normal"},[_vm._v("Service fee ("+_vm._s(_vm._f("formatNumber")(_vm.vm.buyerTax,5, 0))+"%) :")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.vm.offerBuyerTaxPrice,5, 0))+" "+_vm._s(_vm.vm.tradeToken))])])]),_c('div',{staticClass:"mt-6 font-italic text-caption text-center font-weight-400"},[_vm._v(" If seller approves your offer. You will recieve a NFT #"+_vm._s(_vm.vm.keyId)+", which contains the details of the invested projects, purchased allocations, and vesting terms. ")]),_c('div',{staticClass:"d-flex justify-space-between text-body-1 font-weight-bold mt-6"},[_c('div',[_vm._v("Total pay :")]),_c('div',[_vm._v(_vm._s(_vm._f("usdCustom")(_vm.vm.buyerTotalPay,0, 2)))])]),_c('div',{staticClass:"subtitle--text text-caption"},[_vm._v("(when successful sale)")])]),_c('div',{staticClass:"d-flex justify-space-between mt-6"},[_c('v-btn',{staticClass:"btn-lowercase",attrs:{"rounded":"","outlined":"","disabled":_vm.vm.buying},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")]),(!_vm.vm.approved)?_c('v-btn',{staticClass:"primary btn-lowercase",attrs:{"rounded":"","loading":_vm.vm.approving},on:{"click":function($event){return _vm.vm.approveUsd()}}},[_vm._v("Approve")]):_c('v-btn',{staticClass:"primary btn-lowercase",attrs:{"rounded":"","loading":_vm.vm.buying},on:{"click":function($event){return _vm.buy()}}},[_vm._v("Confirm")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }